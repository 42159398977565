import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog41 from '../images/blogs/blog-images/cookscape-blog-41 .webp';
import blog42 from '../images/blogs/blog-images/cookscape-blog-42 .webp';
import blog43 from '../images/blogs/blog-images/cookscape-blog-43 .webp';
import blog44 from '../images/blogs/blog-images/cookscape-blog-44 .webp';
import blog45 from '../images/blogs/blog-images/cookscape-blog-45 .webp';
import blog46 from '../images/blogs/blog-images/cookscape-blog-46 .webp';
import blog47 from '../images/blogs/blog-images/cookscape-blog-47 .webp';
import blog48 from '../images/blogs/blog-images/cookscape-blog-48 .webp';
import blog49 from '../images/blogs/blog-images/cookscape-blog-49 .webp';
import blog50 from '../images/blogs/blog-images/cookscape-blog-50 .webp';

import arfathima from '../images/blogs/architects/Cookscape-Architect-Moizu-Fathima .webp';
import ardarshana from '../images/blogs/architects/Cookscape-Architect-Darshna .webp';
import arvignesh from '../images/blogs/architects/Cookscape-Architect-Vignesh-Kumar .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
import arpriyatharshini from '../images/blogs/architects/Cookscape-Architect-Priyatharshini .webp';
import arvarshini from '../images/blogs/architects/Cookscape--Architect-varshini .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
import arsowmi from '../images/blogs/architects/Cookscape-Architect-Sowmithra .webp';
import argomathi from '../images/blogs/architects/Cookscape-Architect-Gomathi-Varshini .webp';

function Blog5() {
  return (
    <>
    <Helmet>
      <title>Blog - Cookscape</title>
    </Helmet>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
    </div>
    <div className="container-fluid aboutus">
        <div className="container">
        <div className="blog-row p-3">
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog41} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'>Unique Design Ideas for Small Kitchen Storage Space</h1>
                <p className='blog-para'> Just because you have a small cooking space doesn’t mean you have to de-prioritize the decor in your kitchen to make it functional. Even if your kitchen is not spacious with marble countertops, endless storage space, </p>
                <Link to="/unique-design-ideas-for-small-kitchen-storage-space" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arfathima} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Moizu Fathima</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog42} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> Exclusive Tips to Maximize Space in Your Wardrobe</h1>
                <p className='blog-para'> Wardrobe plays a major role in our daily life, which can also be considered as a treasure of our lifestyle. There are different types of wardrobes that can be classified depending on our type of functionality and our lifestyle. </p>
                <Link to="/exclusive-tips-to-maximize-space-in-your-wardrobe" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={ardarshana} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Darshna</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog43} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> 
                What services does Cookscape offer?</h1>
                <p className='blog-para'> From the term Cookscape, itself it’s clear that it’s related to kitchen or cooking space and it does not needs any introduction in between people in this field in Chennai. Spanning a Legacy of over 20 years, Cookscape has positioned itself as a one point solution for.....</p>
                <Link to="/what-services-does-cookscape-offer" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsaranya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Saraniya</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog44} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> 10 Questions, You must ask your Interior Designer</h1>
                <p className='blog-para'> When basic requirements are met then a house can become a functional dwelling however, to make it a home one would have to exclusively customize it according to their needs.</p>
                <Link to="/10-questions-you-must-ask-your-interior-designer" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arvignesh} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Vignesh Kumar</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog45} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> Transform your Bathroom into “Home Spa”</h1>
                <p className='blog-para'> It’s easier than you think to turn your bathroom into your Home Spa. Equipped with a relaxing shower or warm bath, it can be the ideal sanctuary for you to unwind and relax, with a few extra elements thrown in. There are few points to list out below....</p>
                <Link to="/transform-your-bathroom-into-home-spa" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arapsana} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Apsana</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog46} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> Ideas to Brighten up a dark Kitchen</h1>
                <p className='blog-para'> Kitchen is one of the most important and integral part of any Residential Interior Design Projects. This is the place where sharp objects like knifes and cutters are used, apart from this people also come into contact with fire here....</p>
                <Link to="/ideas-to-brighten-up-a-dark-kitchen" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={aranu} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Anulekha</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog47} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> How to design your bedroom ideal for the peaceful sleep using interior</h1>
                <p className='blog-para'> The bedroom is the perfect place at home for relaxation and refuvenation.it has an important role to design a bedroom with suitable interior which is includes color, pattern, texture and furniture.</p>
                <Link to="/how-to-design-your-bedroom-ideal-for-the-peaceful-sleep-using-interior" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arpriyatharshini} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Priyatharshini   </h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog48} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> Interior Design Ideas for Latest Pooja Room Design</h1>
                <p className='blog-para'> Pooja is the adobe of God present in our homes. The Pooja room/Mandir should always be placed in the North, East or the Northeast side of the home. Pooja space is the most important part of our house, as it keeps our house filled with positive energies.
                </p>
                <Link to="/interior-design-ideas-for-latest-pooja-room-design" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arvarshini} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Varshini</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog49} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> LOVELY WARDROBE DESIGN IDEAS FOR YOUR KIDS: A DESIGN FOR THE CHANGING WORLD</h1>
                <p className='blog-para'>Usually the idea about designing anything for the kids from smaller DIY dresses to building a real play house for them makes us really excited too. Because half the time our thinking goes about, what’s trending in the kids world....</p>
                <Link to="/lovely-wardrobe-design-ideas-for-your-kids-a-design-for-the-changing-world" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsowmi} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Sowmithra</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog50} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> Stay-updated-with-the-latest-design-trends!</h1>
                <p className='blog-para'> Add a playful mix of vibrant colours, textures, patterns and decors. Addition of a bold colour to the light colour room gives your home a new life than the neutral or cool tones such as grey, white and black that has served us the past.</p>
                <Link to="/stay-updated-with-the-latest-design-trends" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={argomathi} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'>Architect – Gomathi Varshini</h1>
                </div>
            </div>
            </div>
        </div>
        <div className='container'>
    <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
        <div class="pagination  align-items-center">
  <Link to="/blog" > <a>&laquo;</a></Link>
  <Link to="/blog" >  <a>1</a></Link>
  <Link to="/blog-2" > <a>2</a></Link>
  <Link to="/blog-3" > <a>3</a></Link>
  <Link to="/blog-4" > <a>4</a></Link>
  <Link to="/blog-5" > <a class="active">5</a></Link>
  <Link to="/blog-6" > <a>6</a></Link>
  <Link to="/blog-7" > <a>7</a></Link>
  <Link to="/blog-8" > <a>8</a></Link>
  <Link to="/blog-8" ><a>&raquo;</a></Link>
    </div>
        </div>
        <div className='col-md-2'></div>
    </div>
</div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog5;