// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog71 from '../images/blogs/blog-images/cookscape-blog-71 .webp';
import arsowmi from '../images/blogs/architects/Cookscape-Architect-Sowmithra .webp';
const Blog8a = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>IMPORTANCE OF INTERIOR DESIGN: A SMALL OVERVIEW.</h1>
                      <img src={blog71} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Interior design, the idea itself makes us go thinking about those beautiful stone cladding backgrounds for the TV Wall or probably a fancy partition or even the simplest chandelier hanging from the elegant ceiling. Even the little girl from high school to the retired couple, have their own set of dreams, from having pink rooms to having an in-house courtyard with a swing. So every person questioned about their own home will definitely have a pre-framed design idea for their very own dream home. So, designing the interiors is not just about beauty and aesthetics but also to, make the space user- friendly.</p>
                      <p className='blog-page-para'> A house made of bricks and walls becomes a home only when there are loves and enthusiasm filled in the air. You start loving your place when it’s convenient for your usage. Imagine having a comfortable pre-set place to put your car keys or place to arrange the wedding invitations, so that you don’t have to search the house upside down to find the same. Imagine having a segregated socks compartment where you don’t have to get irritated searching for the lost one. That’s all about the interiors. You spend time to work on every minute detail to make sure that your everyday movement in the house is non-chaotic and friendly.</p>
                      <p className='blog-page-para'> And also the prevailing myth is that anything that has to do with interior design might dig a big hole in our pocket. But interior design is basically the art of making any space look better and user-friendly with the creative touch. So, while playing with closed spaces, minimalism plays a major role. There are different ways to do the interiors, wherein following the style of the ancient art movements like the Art deco or Renaissance would involve the usage of antiques furniture and objects.</p>
                      <p className='blog-page-para'> This would be the style where the interiors are louder than the architecture itself. That’s an altogether different topic. But we, the interior designers in Chennai or probably in India work mostly for residences in designing better spaces for everyday lives. Here, we basically work on efficiently maximizing the usage of the spaces while making it look aesthetically appealing.</p>
                      <p className='blog-page-para'> So definitely to work on the interiors, a major part is in your hand where you might have to convey your style of living and expectations of the outlook of your designed interiors. So the designer is going to help you design your dreams and visualize it while professionally sorting it out.</p>
                      <img src={arsowmi} alt="Architect-Sowmithra" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Sowmithra</h1>
                  </div>
                </div>    
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
</>
              );
            };
export default Blog8a;
