import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog34 from '../images/blogs/blog-images/cookscape-blog-34 .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
const Blog4d = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>What is Premium Vanity?</h1>
                        <img src={blog34} alt="cookscape-premium-vanity" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>This a rich looking yet simplistic kind of variant. It has a lacquered finish with a varnish coating that gives a reflective appearance to the kitchen, it is most suited for kitchens where more of grilling and microwaving activates are performed. It has a Glossy Painted Plywood Finish with three coats of spray paint and one coat of varnish to give this variant a glossy look. Any Pastel color from Asian Paints can be applied to this variant. Most suited for wardrobes, TV. Units. This is a premium quality product that comes with 10 years of Replacement Guarantee, as Boiling Water Proof (BWP-Highest grade) Plywood is used in this variant. This variant like our Vanity Variant is handle less and can be opened with the help of niche engraved on the exterior shutters of the cabinets.</p>
                        <h2 className='blog-page-title-2 pt-3'>Key Points & Features:</h2>
                        <p className='blog-page-para'>• Highlight – Glossy Contemporary Finish and Stellar Looks.</p>
                        <p className='blog-page-para'>• Kitchen Carcass Boiling Water Proof (BWP) – IS710 Grade.</p>
                        <p className='blog-page-para'>• Wardrobe Carcass: Boiling Water Proof (BWP) – IS710 Grade.</p>
                        <p className='blog-page-para'>• Shutter Material: HIGH Density Fiber.</p>
                        <p className='blog-page-para'>• Shutter Finish: 1.2 mm Tough Gloss / Scratch Proof</p>
                        <p className='blog-page-para'>• Shutter Finish: Lacquered Paint + Varnish Finish.</p>
                        <p className='blog-page-para'>• Primary Hardware: EBCO (12 Kg).</p>
                        <p className='blog-page-para'>• Skirting: Matching SF Skirting / Rehau Skirting.</p>
                        <img src={aranu} alt="Architect-Anulekha" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Anulekha</h1>
                      </div>
                    </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog4d;
