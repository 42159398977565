import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog25 from '../images/blogs/blog-images/cookscape-blog-25 .webp';
import arashika from '../images/blogs/architects/Cookscape-Architect-Aashika .webp';

const Blog3e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>What is calypso variant?</h1>
                      <img src={blog25} alt="cookscape-calypso-variant" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>These Rugged and Robust frames, handles & post formed laminated shutters transform the space and revamps the ambience effortlessly. The sight of this variant is exceptionally alluring and it is also extremely durable. When in doubt, the choice of this variant can never go wrong as it can seamlessly camouflage into space and justify its selection.</p>
                      <h2 className='blog-page-title-2 pt-3'>Key Points & Features:</h2>
                      <p className='blog-page-para'>• Kitchen carcass: Boiling water resistance (BWR)</p>
                      <p className='blog-page-para'>• Kitchen shutter: Medium density Fiberboard (MDF)</p>
                      <p className='blog-page-para'>• Wardrobe Carcass: Boiling water resistance (BWR)</p>
                      <p className='blog-page-para'>• Primary Hardware: EBCO (12 Kg)</p>
                      <p className='blog-page-para'>• Skirting: Primary SF skirting/ Rehau Skirting</p>
                      <p className='blog-page-para'>This variant comes with lifetime warranty which is generally 7 years for engineered wood. Even though a star variant once, it’s recently not preferably suggested to our clients due to few basic reasons. As already mentioned since it has post formed laminated shutters, those edges are not compatible to accommodate edge banding which is highly essential to prevent delamination.</p>
                      <p className='blog-page-para'>Delamination is a phenomenon that features peeling of laminates due to the absence of edge banding. The prominent role of an edge banding is that it prevents dust and small insects or pests from invading. Moreover MDF shutters are not waterproof so they obviously do bulge and break in case of being exposed to loads.</p>
                      <p className='blog-page-para'>But in spite of these this variant stands apart and continues to reign as everyone’s personal favorite due to its impeccable finishes and tantalizing look.</p>
                      <img src={arashika} alt=" Architect-Aashika" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Aashika</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog3e;
