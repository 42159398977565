import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog45 from '../images/blogs/blog-images/cookscape-blog-45 .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
const Blog5e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>Transform your Bathroom into “Home Spa”</h1>
                        <img src={blog45} alt="cookscape-home-spa" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>It’s easier than you think to turn your bathroom into your Home Spa. Equipped with a relaxing shower or warm bath, it can be the ideal sanctuary for you to unwind and relax, with a few extra elements thrown in. There are few points to list out below</p>
                        <h2 className='blog-page-title-2 pt-3'>1. Relax and Rejuvenate at Home</h2>
                        <p className='blog-page-para'>From astronomical housing prices to the ever-growing popularity of tiny home living, there is a wide range of reasons to ensure that you are working with a small bathroom. But just because it’s small doesn’t mean it also can’t be a relaxing and rejuvenating oasis. </p>
                        <p className='blog-page-para'>Even the smallest update  makes  a  big  impact  on  the  overall </p>
                        <p className='blog-page-para'>aesthetic. The key is knowing what, exactly, those updates should be to achieve that coveted spa-like atmosphere.</p>
                        <p className='blog-page-para'>When you are fully involved to the tiny home lifestyle or you are simply working with less square footage than you’d like, these tricks will transform your bathroom and make everyday a spa day.</p>
                        <h2 className='blog-page-title-2 pt-3'>2. Gilded Goodness</h2>
                        <p className='blog-page-para'>Little feels more luxurious than gold details in the bathroom, and integrating them could not be easier. Shifting your current handles,pulls, and faucets for a gilded update and your bathroom will instantly feel more luxury and lovely.</p>
                        <h2 className='blog-page-title-2 pt-3'>3. Changing Bathtub</h2>
                        <p className='blog-page-para'>If you currently have a bathtub, you’re missing out on some serious spa vibes. This simple addition makes a big impression and it comes in a variety of style from reclaimed wood to Lucite.</p>
                        <h2 className='blog-page-title-2 pt-3'>4. Arrangement in Shelving</h2>
                        <p className='blog-page-para'>Shelving is a small space dweller’s best friend, especially when it comes to the organizational challenge that is a small bathroom. Installation of open-shelving on your walls, adding aesthetic appeal arrangement for towels, fancy soaps, and a decorative accent or two.</p>
                        <h2 className='blog-page-title-2 pt-3'>5. Aromatic Plants</h2>
                        <p className='blog-page-para'>Plants have a unique way of making a room feel pleasing also more inviting and sophisticated, and the bathroom is also included. Consider incorporating a few easy to care for succulents or air plants for a low-maintenance and absorb bad odor from the bathrooms.</p>
                        <h2 className='blog-page-title-2 pt-3'>6. Inventing Art work</h2>
                        <p className='blog-page-para'>Just as artwork makes a living room or bedroom design more dynamic, it also make the same for a bathroom. Integrating a large print or a gallery wall will add a dimensional layer of intrigue to your design while making it appear more complete and good appealing.</p>
                        <h2 className='blog-page-title-2 pt-3'>7. Transform with Towel Storage</h2>
                        <p className='blog-page-para'>A wooden ladder in lieu of a traditional towel bar (or in addition to one) will immediately warm up your look while providing a proper place for you to showcase the fancy towels we’re about to discuss.</p>
                        <img src={arapsana} alt="Architect-Apsana" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Apsana</h1>
                      </div>
                    </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog5e;
