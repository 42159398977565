import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog57 from '../images/blogs/blog-images/cookscape-blog-57 .webp';
import blog57a from '../images/blogs/blog-images/cookscape-blog-57a .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
const Blog6g = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>HOW ARE WE GOING TO RESUME OUR OFFICE AFTER LOCKDOWN?</h1>
                        <img src={blog57} alt="cookscape-design-ideas" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>In the beginning of 2020 we were hit by a pandemic which created history! The Sars-Cov-2 virus has affected over 4 billion people around the world and counting; much of our lives has come to a standstill. However, things seem to be improving as the covid-19 situation has been controlled and managed well in India by the government, who has issued a nationwide lockdown from the end of March. The government has now planned to give some relaxation and allow businesses to start operating again provided that they take precautionary measures to help check the spread of the Virus. Thus, we at Cookscape are taking measures to ensure that we can get back to business in a Safe & Secure manner.</p>
                        <h2 className='blog-page-title-2 pt-3'>These measures include:</h2>
                        <img src={blog57a} alt="cookscape-design-ideas" class="img-fluid pt-3"/>
                        <p className='blog-page-para'> 1. A carefully planned approach to starting of operations of our office through which we will identify those staff members who are supposed to attend office on certain days. So that others can continue working from home.</p>
                        <p className='blog-page-para'> 2. We have also decided that won’t permit overcrowding in our Studios as one architect will be handling only one client and his her family at a time. Also, we will only entertain up to 12 appointments per day.</p>
                        <p className='blog-page-para'> Apart from this, we have created guidelines for employees that they are advised to follow diligently. </p>
                        <h2 className='blog-page-title-2 pt-3'>The guidelines are as follows: </h2>
                        <p className='blog-page-para'> a. Every employee must at all times wear masks and gloves and they should never leave home without it. They must also periodically keep replacing them during the day.</p>
                        <p className='blog-page-para'>b. Sanitize or wash hands after touching any foreign surface especially while traveling to the office and try not to touch face or mouth before cleaning hands thoroughly.</p>
                        <p className='blog-page-para'>c. Before entering the office all staff personnel must make sure that they clean their hands with hand sanitizer containing a minimum of 60% alcohol.</p>
                        <p className='blog-page-para'>d. Travel carefully to work and preferably travel by personal vehicle or ensure that the means of transportation used to commute to office has social distancing measures in place.</p>
                        <p className='blog-page-para'>e. Please refrain from coughing and sneezing in public and kindly keep tissues or handkerchief handy.</p>
                        <p className='blog-page-para'>f. Bring your own stationery from home so as to decrease the risk of contamination.</p>
                        <p className='blog-page-para'>h. Keep cleaning hand and face at regular intervals and wash hands thoroughly after using the washroom.</p>
                        <p className='blog-page-para'>We are also making sure that the staff gets the temperature checked before entering the office premises. Also, we have advised all our employees to seek immediate medical attention and inform others as well if they get any symptoms related to Covid-19 like cold, fever, breathlessness, or loss of sense of taste or smell.</p>
                        <p className='blog-page-para'>These are all mandatory precaution measures that we have put to place. These must be followed by each and every member of our Cookscape family to ensure their own safety as well as the safety of their colleagues and family members.</p>
                        <img src={aranu} alt="Architect-Anulekha" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Anulekha</h1>
                      </div>
                    </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6g;
