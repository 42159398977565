import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog15 from '../images/blogs/blog-images/cookscape-blog-15 .webp';
import blog15a from '../images/blogs/blog-images/cookscape-blog-15a .webp';
import arraakesh from '../images/blogs/architects/Cookscape--Architect-raakesh-natarajan .webp';
const Blog2e = () => {
        return (
                <>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                    <h1 className='blog-page-title pt-3'>What Is The Best Material For An Interior Design?</h1>
                    <img src={blog15} alt="cookscape-best-material-for-an-interior-design" class="img-fluid pt-3"/>
                    <p className='blog-page-para'>Plywood is a good and favorite material for interior design as it’s very light and can easily be customized. It can be applied to not only furniture but also to ceilings, walls, and partitions. Plywood gives a contemporary look to the design and has a great appeal. </p>
                    <h2 className='blog-page-title-2 pt-3'>Plywood Definition</h2>
                    <p className='blog-page-para'>It is a factory-manufactured wood product created by sticking together thin sheets of veneer with a resin under high pressure. This makes a thick, strong & flexible flat board. It is a popular material as it is less expensive than wood and lasts longer than it. This is because it is treated and doesn’t get warped or shrink for many years. </p>
                    <h2 className='blog-page-title-2 pt-3'>Plywood as a Material</h2>
                    <p className='blog-page-para'>This plywood is budget-friendly as it is cheap and flexible. Panels made of these are cheap, easy to install, and is recycle friendly. Even though it was first made in the 20th Century it is immensely popular today and its popularity doesn’t seem to be diminishing in the future.</p>
                    <h2 className='blog-page-title-2 pt-3'>Plywood for interior design – For walls and furniture</h2>
                    <p className='blog-page-para'>If a good and stylish design has to be created then Plywood is the right choice for good interior design for new homes and renovations at an affordable price. Wallboard and Panel designs are ideal for Bedrooms, entrance halls, bathrooms and Wall mounted and floor-mounted cabinets are suitable for Kitchens.</p>
                    <p className='blog-page-para'>The warm hue of wood goes well with materials such as steel & concrete. Some popular kinds of plywood are birch, beech, pine, and spruce all of these have a soft finish of light wood.</p>
                    <h2 className='blog-page-title-2 pt-3'>Best Plywoods Available</h2>
                    <p className='blog-page-para'>A total of 18 plywoods are currently available in the market and each of these is based on the type of wood used, application, and process. Here the focus is going to be on the three most used types MR ply, BWR/BWP ply, and Marine ply. </p>
                    <h2 className='blog-page-title-2 pt-3'>Moisture Resistant Plywood</h2>
                    <p className='blog-page-para'>This is known as Commercial Ply. It has reasonably good resistance to moisture but it is not completely waterproof. However, it is quite a popular choice for interior furnishing. </p>
                    <p className='blog-page-para'>Boiling Water Resistant and Boiling Water Proof Ply </p>
                    <p className='blog-page-para'>This kind of Plywood is perfect for interior as well as exterior use. This is waterproof and is suitable for all weather conditions. It can be used in wet areas as well such as Bathrooms and Kitchens. It is also suitable for wall cladding on the exterior façade, staircases, and other areas as well. </p>
                    <img src={blog15a} alt="cookscape-best-material-for-an-interior-design" class="img-fluid pt-3"/>
                    <h2 className='blog-page-title-2 pt-3'>Marine Ply</h2>
                    <p className='blog-page-para'> This is a great quality of plywood and is also waterproof to a great extent. Hence it is used in building boats and other industries where water contact is common.</p>
                    <p className='blog-page-para'> Plywood is the best product for interior design as it is long-lasting, can be used for a prolonged period of time and it is a highly flexible product. It is a sturdy product and the second most strong material for interior design after wood. It is also free from Warping and any other damage caused because of moisture exposure. It is a budget-friendly product and also eco-friendly as it can be used and reused. Hence it is the best product available in the market right now for Interior Design works for your Home!</p>
                    <img src={arraakesh} alt="Architect-Raakesh-Natarajan" class="blog-page-author-img pt-3"/>
                    <h1 className='blog-title pt-3'> Architect Raakesh Natarajan</h1>
                  </div>
              </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog2e;
