import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog65 from '../images/blogs/blog-images/cookscape-blog-65 .webp';
import arneshma from '../images/blogs/architects/Cookscape-Architect-Neshma .webp';
const Blog7f = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>CRAZY AND INNOVATIVE IDEAS FOR KITCHEN STORAGE ORGANIZATION</h1>
                      <img src={blog65} alt="cookscape-kitchen-storage-ideas" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>“If you can organize your kitchen, you can organize your life”-Louis Parish.</p>
                      <p className='blog-page-para'> These words emphasize the importance of an organized kitchen which is equitably essential for the vital functioning of a kitchen-irrespective of its size.</p>
                      <p className='blog-page-para'> We tend to store a lot of things required for cooking and this calls for a lot more storage space in the kitchen. But having smart kitchen storage is easy said than done.</p>
                      <p className='blog-page-para'> Let us take a look as to why kitchen storage is so important for the modern-day homes.</p>
                      <p className='blog-page-para'> 1. Ease in cooking, thereby decreasing the preparation time.</p>
                      <p className='blog-page-para'> 2. Effective Utilization of Limited Space.</p>
                      <p className='blog-page-para'> 3. The durability of crockery and kitchen accessories.</p>
                      <p className='blog-page-para'> The organization is not a satisfactory job alone but it’s double the fun when we can make it more interesting through creative and crazy ideas.</p>
                      <h2 className='blog-page-title-2 pt-3'>1. Pot and Pan lid holder</h2>
                      <p className='blog-page-para'> There are a lot of ideas available in the market for the pot and pan lid holder but the main idea is to keep one of the most fought pan lids to keep organized since most of the time these are not required for cooking</p>
                      <h2 className='blog-page-title-2 pt-3'>2. Open up</h2>
                      <p className='blog-page-para'> Open shelving and door-less cabinets can work wonders for a tiny kitchen.</p>
                      <p className='blog-page-para'> These shelves could be used to store your favourite kitchenware along with artworks and color-coded recipe books and hence creating a mini-masterpiece.</p>
                      <h2 className='blog-page-title-2 pt-3'>3. Height Matters</h2>
                      <p className='blog-page-para'> Organizing the stuff in your kitchen cabinet by height can actually make it look ordered and organized and will create a sophisticated display.</p>
                      <h2 className='blog-page-title-2 pt-3'>4. Hang it up</h2>
                      <p className='blog-page-para'> A handful of hanging cup hooks can change the life of your kitchen. Just employ these hooks just anywhere in your kitchen and it allows you to display your mugs in style at the same time all of them together creating a piece of art in return.</p>
                      <h2 className='blog-page-title-2 pt-3'>5. Pegboard</h2>
                      <p className='blog-page-para'> The best way to free up your cabinet from large pots and pans is to a standard pegboard and use them to hang up your bulky kitchenware.</p>
                      <p className='blog-page-para'> These are some of the tips and tricks to make your kitchen organizations look less bulky and at the same time to create an artful impact on the space without spending much time and money.</p>
                      <img src={arneshma} alt="Architect-Neshma" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Neshma</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog7f;
