import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog69 from '../images/blogs/blog-images/cookscape-blog-69 .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
const Blog7c = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>TECHNOLOGY AND THE FUTURE OF INTERIOR DESIGN INDUSTRY</h1>
                        <img src={blog69} alt="cookscape-future-interior-design" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>In the past few decades, technology has undoubtedly had a huge impact on all walks of ‘Modern’ life, it has also had an equally remarkable impact on the Home Interior Design industry in every part of the world including, Chennai, the southern metropolitan city of India. Although, the future of Interior Design remains ambiguous and tricky to predict as professionals across the map struggle to determine where the world might be in fifty years’ time, it is an eminent fact that the role of technology in the design industry will never diminish.</p>
                        <p className='blog-page-para'>Technology is playing a huge role in updating homes. New residential solutions known as “Smarthomes” are becoming a very common phenomena especially in metro cities around the world and in India. Today the best interior design companies in Chennai are trying to inculcate new technology into home interiors to make offices, homes and other spaces more comfortable for their clients. Hence, Smarthomes are the ‘talk of the town’. Barely a day goes by without one of the tech giants releasing a new gadget designed to make homes more connected. For instance, Google Home has not only successfully been in the market a while now but also has become a popular gadget which is being installed in various residences around the world. It is basically a central home control system that not only acts as a personal assistant but also an alarm and speaker all rolled into one.</p>
                        <p className='blog-page-para'>In the coming years, experts anticipate that smarthomes will be much more than just standalone devices. Smart interiors will be built into the very structure of modern homes. For example, Tipic, an Italian designer company with their unique Tulèr kitchen worktop is pioneering smart kitchen integrations. Made of a slab of quartz composite, the smart work surface inculcates a sink that appears as you wave your hand over a sensor.</p>
                        <p className='blog-page-para'> This trend of technology playing a major part in making homes more comfortable seems to be growing at an exponential rate thus the future of Interior design seems to be bright and vibrant as well as “cool”</p>
                        <img src={aranu} alt="Architect-Anulekha" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Anulekha</h1>
                    </div>
                </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog7c;
