import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog1 from '../images/blogs/blog-images/cookscape-blog-1 .webp';
import blog1a from '../images/blogs/blog-images/cookscape-blog-1a .webp';
import armanikandan from '../images/blogs/architects/Cookscape--Architect-manikandan .webp';
const Blog1a = () => {
  return (
    <>
    <Helmet>
      <title>Blog - Cookscape</title>
    </Helmet>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
    </div>
     <div className='container-fluid aboutus'>
        <div className='container p-5'>
          <h1 className='blog-page-title pt-3'>Understanding the Theme and Concept in Interior Design</h1>
          <img src={blog1} alt="cookscape-theme-concept" class="blog-img-size pt-3"/>
          <p className='blog-page-para'>Hi everybody, welcome to our Cookscape family community. In this unique circumstance, you can get data about various kinds of ideas, styles, themes, design components, administrations, and numerous things connected with interior design. We trust that our experience will assist you with grasping this immense innovative field. </p>
          <h2 className='blog-page-title-2'>What is the Idea and Theme of Interior design?</h2>
          <p className='blog-page-para'>Interior design is a profoundly proficient and enormous field where every creator follows an alternate example in a specific entryway. Each architect has their own process of designing from origination to the last completing of the venture. The earliest reference point of the design or thoughts begins with the idea and topic which are the primary components of each design. </p>
          <p className='blog-page-para'>As a rule, an idea is a theoretical thought, a bunch of design components, or the development of design. </p>
          <p className='blog-page-para'>A design idea is a crucial thought that gives the design its profundity, importance, and course. An idea can be practical or unique. It very well may be in any structure like workmanship, custom, history, and contemporary environmental factors. There are changed two sorts of ideas. </p>
          <p className='blog-page-para'><span className='blog-page-sub-title'>Visual:</span> It characterizes the noticeable setting of configuration, design, arranging, structure, colors, and so on, they are centered more around the method for passing on the message. </p>
          <p className='blog-page-para'><span className='blog-page-sub-title'>Abstract/Verbal:</span> It characterizes idea/setting, may be in words that grow to portray the narrative of design. This idea may be tastefulness, effortlessness, financial design cordial design, etc.,.. which impact the imperceptible themes. They can be more powerful in the setting in which the design is to be imparted. </p>
          <h2 className='blog-page-title-2 pt-3'>Concept: characterized as a theoretical thought</h2>
          <p className='blog-page-para'>Theme: explicit theme or thoughts that repeat specific piece of work </p>
          <p className='blog-page-para'> • By scope:</p>
          <p className='blog-page-para'> Concept: extension is restricted</p>
          <p className='blog-page-para'> Theme: scope plays an enormous part</p>
          <p className='blog-page-para'> • By specific:</p>
          <p className='blog-page-para'>  Concept: has explicit thoughts that arrangement with it</p>
          <p className='blog-page-para'>  Theme: number of ideas fall under an alternate topic</p>
          <p className='blog-page-para'>  Instructions to start the idea for Interior design</p>
          <p className='blog-page-para'>  After the underlying gathering with the client, the subsequent stage is to start the idea, for that two measures must be inspected</p>
          <p className='blog-page-para'>  • Characterizing the issue</p>
          <p className='blog-page-para'>  • Research for the area/site</p>
          <h2 className='blog-page-title-2'>Characterizing the issue:</h2>
          <p className='blog-page-para'>  The idea is the answer for all design related issues. With respect to fostering the idea, you want to pose specific inquiries to the</p>
          <p className='blog-page-para'>  • Business clients about the brand, their clients, and their objectives for the site.</p>
          <p className='blog-page-para'>  • Business clients about the brand, their clients, and their objectives for the site.</p>
          <p className='blog-page-para'>  • With respect to the private clients, think about asking their desires, prerequisite, need and vision for the site</p>
          <img src={blog1a} alt="cookscape-theme-concept" class="blog-img-size pt-3"/>
          <p className='blog-page-para'> <span className='blog-page-sub-title'>Research Process:</span> It characterizes the noticeable setting of configuration, design, arranging, structure, colors, and so on, they are centered more around the method for passing on the message.</p>
          <p className='blog-page-para'> <span className='blog-page-sub-title'>Applying the Concept:</span> Apply the style or theme, as once you have a completed floor design currently apply the design idea to this design. This is the point at which you currently select furnishings, adornments, colors, floor coverings, window medicines, and wall wraps up fitting the style</p>
          <h2 className='blog-page-title-2'>What is the theme of interior design?</h2>
          <p className='blog-page-para'> There is a sub-part of the idea. A theme is a bringing together or predominant idea or example that passes around a given thought. The idea for that particular space gives solidarity, setting, and story.</p>
          <p className='blog-page-para'> Being an instrument of the design, the cycle turns into what which is a determination of varieties, surfaces, materials, structures, or even a theoretical</p>
          <p className='blog-page-para'>  Eg: the style of variety, sensitive vibe, feel of surface, examples and shapes, arrangement, and so on.,</p>
          <p className='blog-page-para'>  In general, the design includes two terms for example idea and topic.</p>
          <p className='blog-page-para'>  Every one contrasts from the other. Every one of the two are persuasive for any design cycle since they give significance and story to the design.</p>
          <p className='blog-page-para'>  The contrast between these two ideas and topic is the idea is an underlying system and answer for any design. Indeed, the theme is an overlay to the idea that assists with teeing every one of the different spaces together. It is a sub-part of the idea which is more thorough than the idea. The idea will show you how to make philosophy and the theme will give you the intention to design and style.</p>
          <p className='blog-page-para'>   Our vision is to make the client’s imaginarily and a blessing from heaven idea with our design potential.</p>
          <img src={armanikandan} alt="Architect-Manikandan" class="blog-page-author-img pt-3"/>
          <h1 className='blog-title pt-3'> Architect Manikandan</h1>
        </div>
      </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog1a;
