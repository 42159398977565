import React from 'react';
import interior from '../images/home/cookscape-interior-designs.webp';
import renovation1 from '../images/gallery/renovation/cookscape-renovation-project-1.webp';
import renovation2 from '../images/gallery/renovation/cookscape-renovation-project-2.webp';
import renovation3 from '../images/gallery/renovation/cookscape-renovation-project-3.webp';
import renovation4 from '../images/gallery/renovation/cookscape-renovation-project-4.webp';
import renovation5 from '../images/gallery/renovation/cookscape-renovation-project-5.webp';
import renovation6 from '../images/gallery/renovation/cookscape-renovation-project-6.webp';
const Renovation = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Renovation Ideas</h3>
    </div>
    <div className='container-fluid aboutus'>
        <div className='container'>
            <p class="renovation-title pt-5">Renovation in 1 day</p>
            <h3 className="renovation-main-title">Designing and Installing safely</h3>
            <p className="renovation-para">Renovation in one day is possible? Look at this video. It is 100% possible for us to remodel your home.</p>
        </div>
    <div className='container'>
        <iframe width="100%" height="550px" src="https://www.youtube.com/embed/HGjK1E2_Vvk">
        </iframe>
    </div>
    <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-4'>
                    <p class="renovation-section-title pt-3">HOW WE INSTALLED</p>
                    <h2 className='renovation-section-title1 pt-3'>6 Easy Steps To Remodel Your Home</h2>
                    <p className='renovation-section-para pt-3'>Home renovation is a fantastic way to refresh your space and make it better suited to your needs. Upgrading old interiors, adding modern features, or even changing the layout, renovation can change your home into something fresh and functional.
                    <br/>
                    <br/>
                    Good planning, of course, and a perfect team of professionals; renovation requires either one room in your house or the entire one, but experts would surely be there to make everything in place, assist in selecting materials, and see your budget. The final product will be a stylishly cozy home that feels just right for you.</p>
            </div>
            <div className='col-md-4'>
              <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">1. Research Plan & Measurements</h2>
                    <p class="services-para">This step is all about how you use your kitchen and finding the layout & features that fit your household’s lifestyle. Our expert visits your home for detailed measurement to home interior design.​</p>
              </div>
              <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">3. Estimates</h2>
                    <p class="services-para">Our team will give a fully designed 3D image of final modern kitchen layout with all costing and details of accessories will be used . After your final approval, contract signed and work is initiated.​</p>
              </div>
              <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">5. Transporting and Installation</h2>
                    <p class="services-para">Our transportation team delivers a stunning new kitchen to your site. Our technical team and experts visit the site to install the entire kitchen in just 3 days.​</p>
              </div>
            </div>
            <div className='col-md-4'>
                <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">2. Schematic design - choose</h2>
                    <p class="services-para">Our Experts design the suitable modern kitchen layout reviews and features that fit your household’s lifestyle. Based on Layout & design you can choose the pattern of kitchen model.​</p>
                </div>
                <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">4. Assistance for site modification</h2>
                    <p class="services-para">While you’re new kitchen system is being manufactured in our factory. Our personal advice team will assist you on minor site modification that can be carried out to make your kitchen look good.​</p>
                </div>
                <div class="renovation-icons">
                    <p class="renovation-icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                    <h2 class="renovation-service-title">6. Final checks and certification</h2>
                    <p class="services-para">Fully installed brand new modular kitchen at your site or home for your final approval and certified with cookscape certification for 10 year Guaranty.​</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container'>
      <h3 class="services-title-2">Renovation Gallery</h3>
          <div class="row justify-content-center">
              <div class="row justify-content-center">
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={renovation1} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={renovation2} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={renovation3} alt="" class="img-fluid"/>
                  </a>
                </div>
              <div class="row justify-content-center pt-3">
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={renovation4} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={renovation5} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={renovation6} alt="" class="img-fluid"/>
                  </a>
              </div>
            </div>
            <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleLightboxLabel">Renovation Gallery</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div id="lightboxExampleCarousel" class="carousel slide">
                                <div class="carousel-inner ratio ratio-16x9 bg-dark">
                                    <div class="carousel-item text-center active">
                                        <img src={renovation1} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation2} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation3} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation4} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation5} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation6} alt="" class="img-fluid mh-100"/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Renovation;
