import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog32 from '../images/blogs/blog-images/cookscape-blog-32 .webp';
import ardinesh from '../images/blogs/architects/Cookscape--Architect-dinesh .webp';
const Blog4b = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid contact'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>What is Alumix Variant?</h1>
                      <img src={blog32} alt="cookscape-alumix-variant" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Designed for contemporary look with enhanced durability. Alumix’s metal alloy framework makes it strong and rust resistant. Also, its toughened glass inlay panel gives it a bright appeal. The Internal Carcass of this variant is made of Boiling Water Proof (BWP- Highest Grade) Plywood. Hence this variant comes with a Replacement Guarantee. The Aluminum alloy framework is made up of Aluminum, Chromium and Stainless steel. Zumila Glass is used on the Exterior Shutters so that they last longer.</p>
                      <h2 className='blog-page-title-2 pt-3'>Key Points & Features:</h2>
                      <p className='blog-page-para'>• Highlight – Metal Variant with Inlay artwork, suitable for stylish new designs & renovations.</p>
                      <p className='blog-page-para'>• Kitchen Carcass Boiling Water Proof (BWP) – IS710 Grade/ Greenply Lam</p>
                      <p className='blog-page-para'>• Wardrobe Carcass: Boiling Water Proof (BWP) – IS710 Grade/ Greenply Lam</p>
                      <p className='blog-page-para'>• Shutter Material: Alumix Frames/Filmed Glass</p>
                      <p className='blog-page-para'>• Shutter Finish: Plain or Frosted Toughened Glass with/without Picture Inlay</p>
                      <p className='blog-page-para'>• Laminate Brand: Zumila Frames / Premium Plus Paint</p>
                      <p className='blog-page-para'>• Primary Hardware: EBCO (12 Kg)</p>
                      <img src={ardinesh} alt="Architect-Dinesh" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'>Architect Dinesh</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog4b;
