import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog51 from '../images/blogs/blog-images/cookscape-blog-51 .webp';
import blog52 from '../images/blogs/blog-images/cookscape-blog-52 .webp';
import blog53 from '../images/blogs/blog-images/cookscape-blog-53 .webp';
import blog54 from '../images/blogs/blog-images/cookscape-blog-54 .webp';
import blog55 from '../images/blogs/blog-images/cookscape-blog-55 .webp';
import blog56 from '../images/blogs/blog-images/cookscape-blog-56 .webp';
import blog57 from '../images/blogs/blog-images/cookscape-blog-57 .webp';
import blog58 from '../images/blogs/blog-images/cookscape-blog-58 .webp';
import blog59 from '../images/blogs/blog-images/cookscape-blog-59 .webp';
import blog60 from '../images/blogs/blog-images/cookscape-blog-60 .webp';

import armeena from '../images/blogs/architects/Cookscape-Architect-Meena .webp';
import arragul from '../images/blogs/architects/Cookscape-Architect-Raghul .webp';
import ardhivya from '../images/blogs/architects/Cookscape-Architect-Dhivya-Arumugam .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
import armuthulakshmi from '../images/blogs/architects/Cookscape-Architect-Muthulakshmi .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
import arfathima from '../images/blogs/architects/Cookscape-Architect-Moizu-Fathima .webp';

function Blog6() {
  return (
    <>
    <Helmet>
      <title>Blog - Cookscape</title>
    </Helmet>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
    </div>
    <div className="container-fluid aboutus">
        <div className="container">
        <div className="blog-row p-3">
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog51} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'>Top-5-Reasons-to-Choose-an-Interior-Design-For-Home!</h1>
                <p className='blog-para'> Here are some ideas to help anyone get a well designed contemporary home. Vertical storages can be included in modest rooms. They are petite and take up less space making the interior volume look light and not affecting the carpet area to a great extent. </p>
                <Link to="/top-5-reasons-to-choose-an-interior-design-for-home" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={armeena} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect – Meena</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog52} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> How to choose the best interior designer?</h1>
                <p className='blog-para'> For a Common man, the biggest dilemma is how to hire an outstanding interior designer who not only will understand his requirements but will also give feasible solutions for the same. </p>
                <Link to="/how-to-choose-the-best-interior-designer" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsaranya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Saraniya</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog53} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> 
                HOW THE MODULAR KITCHEN HELP US? & TIPS TO MAINTENANCE!</h1>
                <p className='blog-para'> Modular Kitchen is now a norm rather than an exception, having changed the age old concepts to beyond style. The Modular Kitchen, Undoubtedly brings convenience & it is the core of any home....</p>
                <Link to="/how-the-modular-kitchen-help-us-tips-to-maintenance" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arragul} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Raghul</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog54} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> 10 EPIC DECORATING IDEAS TO YOUR KIDS ROOM</h1>
                <p className='blog-para'> Before thinking about decorating your kid’s bedroom, it’s important to find out what your kids want. Each and every kid is born with Imagination and creativity. Childhood is a magical time. </p>
                <Link to="/10-epic-decorating-ideas-to-your-kids-room" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={ardhivya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Dhivya Arumugam</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog55} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> WHY DOES AN INTERIOR DESIGNER MATTER?</h1>
                <p className='blog-para'> An interior designer is one who is creative in mind, style in design and approach. Every designer has visual foot prints in Interior Design field. He discovers from the culture and brings essence into the life, Creates a vibe into the space through design and ideas,  </p>
                <Link to="/why-does-an-interior-designer-matter" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={armeena} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'>Architect Meena</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog56} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> TIPS ON CHOOSING INTERIOR CONTRACTOR FOR RESIDENCE!</h1>
                <p className='blog-para'> You already have an idea for Interior Design for your home. However without the right type of Interior Designer this will not fructify in real life. A right Interior Designer is needed to fulfill your needs and wants so that you can live in your dream house.... </p>
                <Link to="/tips-on-choosing-interior-contractor-for-residence" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arapsana} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Apsana</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog57} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> HOW ARE WE GOING TO RESUME OUR OFFICE AFTER LOCKDOWN?</h1>
                <p className='blog-para'> In the beginning of 2020 we were hit by a pandemic which created history! The Sars-Cov-2 virus has affected over 4 billion people around the world and counting; much of our lives has come to a standstill. However, things seem to be improving as the covid-19....</p>
                <Link to="/how-are-we-going-to-resume-our-office-after-lockdown" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={aranu} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Anulekha  </h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog58} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> WHAT IS MAGICAL WONDER WILL HAPPEN AFTER COMPLETING YOUR HOME INTERIOR DESIGN?</h1>
                <p className='blog-para'> False ceilings are not only decorative but also used as air regulators, sag resistors, thermal insulators, etc. It’s available in various types of finishes from wood to glass and fiber to plastic. It also makes the space look compat anc cozy.....
                </p>
                <Link to="/what-is-magical-wonder-will-happen-after-completing-your-home-interior-design" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={armuthulakshmi} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Muthulakshmi</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog59} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> WHY AN INTERIOR DESIGNER MATTER?</h1>
                <p className='blog-para'>Every individual has the capacity to decorate their personal space, but when it comes to creating that personal space conducive of the area for togetherness, it’s very much suggested to consult an Interior designer.....</p>
                <Link to="/why-an-interior-designer-matter" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsaranya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Saraniya</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog60} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> FACTORS TO CONSIDER WHILE CHOOSING AN INTERIOR DESIGN COMPANY</h1>
                <p className='blog-para'> Getting a well-designed interior is never difficult if you really hire the right person to do the job. Hence to get a good outcome you would need to carefully choose a good designer who understands your taste and style and will be able to give you your sweet home of your dreams.</p>
                <Link to="/factors-to-consider-while-choosing-an-interior-design-company" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arfathima} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'>Architect Moizu Fathima</h1>
                </div>
            </div>
            </div>
        </div>
        <div className='container'>
    <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
        <div class="pagination  align-items-center">
  <Link to="/blog" > <a>&laquo;</a></Link>
  <Link to="/blog" >  <a>1</a></Link>
  <Link to="/blog-2" > <a>2</a></Link>
  <Link to="/blog-3" > <a>3</a></Link>
  <Link to="/blog-4" > <a>4</a></Link>
  <Link to="/blog-5" > <a>5</a></Link>
  <Link to="/blog-6" > <a class="active">6</a></Link>
  <Link to="/blog-7" > <a>7</a></Link>
  <Link to="/blog-8" > <a>8</a></Link>
  <Link to="/blog-8" ><a>&raquo;</a></Link>
    </div>
        </div>
        <div className='col-md-2'></div>
    </div>
</div>
    </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog6;