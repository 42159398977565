import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog3 from '../images/blogs/blog-images/cookscape-blog-3 .webp';
import blog3a from '../images/blogs/blog-images/cookscape-blog-3a .webp';
import arraakesh from '../images/blogs/architects/Cookscape--Architect-raakesh-natarajan .webp';
const Blog1c = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>10 Major Kitchen Storage Mistakes To Avoid!</h1>
                        <img src={blog3} alt="cookscape-kitchen-storage" class="blog-img-size pt-3"/>
                        <p className='blog-page-para'>Dismissing the size of the kitchen, never hold back on kitchen cupboards. Regardless of whether you have a more modest kitchen space, the right sort of cupboards will give you more than adequate extra room. The best thing about cupboards is that they likewise add to the style of the space. For example, in the event that you have a more modest kitchen, picking cupboards with a more expanded upper unit makes your space look bigger. </p>
                        <p className='blog-page-para'>Attempt to utilize wall spaces actually. Cupboards can be laid into the wall to permit really working space. In the event that you are thinking about ceramics cupboards, an inbuilt one or a wall-mounted one is smart. It likewise goes about as a critical stylistic layout component. Inside fashioners frequently call blind corners a kitchen’s Bermuda triangle. This is an unusually molded corner </p>
                        <p className='blog-page-para'>space made when the edges of two bordering columns of kitchen cupboards meet. Typically, this is utilized to take care of things that are not utilized frequently. What you are left with is more than adequate, underutilized space in the kitchen that can be changed over into a wonderful stockpiling region. The most effective way to tackle the issue of visually impaired corners is to introduce pull-out racks. These racks can be redone relying upon the size of the space. You can store different kitchen fixings, utensils, kitchen towels, and much more. </p>
                        <img src={blog3a} alt="cookscape-kitchen-storage" class="blog-img-size pt-3"/>
                        <p className='blog-page-para'>On the off potential for success that you have back and truly investigate your kitchen, you will see that there are such countless unused spaces. The region over the cooler, for instance, can be changed over into a brilliant spot for a microwave. Essentially, adding kitchen cupboards to your space can be utilized for putting consistently utilized food items and utensils. A simple choice to use all of your kitchen space is to utilize add-on racks. These racks can be put on the walls of kitchen cupboards, in the back area of huge kitchen pantries, and so forth. The ledges can likewise function as a stockpiling region in the event that you plan well. On the off chance that you have a sufficiently huge working station, utilizing little retires and drawers on it can benefit you. Taking care of cutlery and your kitchen knives is perfect. </p>
                        <p className='blog-page-para'>If you need a study in your small and cosy room but there’s no space for a separate study unit; what do you do? The result to this problem is relatively simple. Just install a foldable office which can fold into the wall. These kind of units come in different shapes and sizes. erected- in- storehouse can also be designed for this unit. This would be the ideal space saving result for a study in your small bedroom. Bedroom can also serve as asemi-office space to do your work from home with a foldable office unit.</p>
                        <p className='blog-page-para'>A Space unit is a cupboard that is put right underneath the top of the kitchen. In current kitchens, another underutilized space is the region over the edge of your current cupboards. Space units make for immense spaces that can be utilized to store numerous things. In kitchens not having a storage space, the space unit is an extraordinary substitute. </p>
                        <img src={arraakesh} alt="Architect-Raakesh-Natarajan" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'> Architect Raakesh Natarajan</h1>
                      </div>
                    </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog1c;
