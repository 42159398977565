import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog28 from '../images/blogs/blog-images/cookscape-blog-28 .webp';
import arfathima from '../images/blogs/architects/Cookscape-Architect-Moizu-Fathima .webp';
const Blog3h = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>What is Veneer Variant?</h1>
                        <img src={blog28} alt="cookscape-veneer-variant" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>Veneer gives a natural look with perfectly smooth and decorative finish that reflects the natural beauty and elegance of fine wood. Natural patterns give an organic and high end look. It comes with PVC Edge Banding that protects its corners and keeps the laminate in place. This variant as the name suggests has a veneer exterior laminate hence the laminate is actually thin layer of real wood made into a sheet. This is why it can give an authentic wood finish look and it comes in wooden colors. It is a Guarantee Product which comes with a 10 year replacement Guarantee. It is a luxury product that will give a rich and natural look to a space</p>
                        <h2 className='blog-page-title-2 pt-3'>Key Points & Features:</h2>
                        <p className='blog-page-para'>• Highlight – Made of Real thin sheet of wood, has a grand appearance and great appeal.</p>
                        <p className='blog-page-para'>• Kitchen Carcass Boiling Water Proof (BWP) – IS710 Grade</p>
                        <p className='blog-page-para'>• Wardrobe Carcass: Boiling Water Proof (BWP) – IS710 Grade</p>
                        <p className='blog-page-para'>• Shutter Material: Veneer</p>
                        <p className='blog-page-para'>• Shutter Finish: Veneer</p>
                        <p className='blog-page-para'>• Primary Hardware: EBCO (12 Kg)</p>
                        <p className='blog-page-para'>• Skirting: Matching SF Skirting / Rehau Skirting</p>
                        <img src={arfathima} alt="Architect-Moizu-Fathima" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Moizu Fathima</h1>
                      </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog3h;
