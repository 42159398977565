import React from 'react';
import interior from '../images/home/cookscape-interior-designs.webp';
import hingedwardrobe from '../images/services/wardrobe/cookscape-hinged-wardrobe .webp';
import alumix from '../images/services/wardrobe/cookscape-alumix-framed-wardrobe .webp';
import sliding from '../images/services/wardrobe/cookscape-sliding-wardrobe .webp';
import walkin from '../images/services/wardrobe/cookscape-walkin-wardrobe .webp';
const Wardrobe = () => {
  return (
    <>
        <div className="container-fluid services-breadcrumb">
            <h3 className="cookscape-services-title">Cookscape Wardrobe Design Ideas</h3>
        </div>
    <div className="container-fluid aboutus">
        <div className="container ">
            <h1 className="interior-title pt-5">Wardrobe Designs</h1>
            <p className="interior-para pt-3">Wardrobes are very important in the home, providing storage space and style. A properly designed wardrobe helps declutter your space and add elegance to a room. This means that you need extra hanging space for clothes, more shelves for folded clothes, or even compartments for storing accessories.

                <br/>
                <br/>
                There are so many different designs, shapes, and materials that you can get a good wardrobe that perfectly fits your space and taste! From sleek modern designs to traditional ones, wardrobes give you lots of storage while keeping a clutter-free stylishness for your room. Investing in a quality wardrobe is one of the ways to enhance the functionality and aesthetics of your home.</p>
        </div>
        <div className="container pt-5">
            <div className="row">
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={hingedwardrobe} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Hinged wardrobe </h1>
                        <p className="interior-para p-3">Hinged wardrobes offer the best possible access to your items through their swung doors and simple design. However, these types of wardrobes require sufficiently big room sizes to permit the complete swinging opening of the doors.
                        </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={alumix} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Alumix Framed wardrobe </h1>
                        <p className="interior-para p-3">Free-standing wardrobes are versatile and can be seen in any room. Because they are not built into the wall, they can be moved and placed anywhere the need for added storage arises.
                        </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={sliding} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Sliding wardrobe </h1>
                        <p className="interior-para p-3">Sliding door wardrobes are considered to be the smart and stylish wardrobe choices for any room. Sliding door wardrobes can save space and are perfect for those who have small rooms or a room with limited clearance.

                        </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={walkin} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">walk-in wardrobe</h1>
                        <p className="interior-para p-3">In terms of pure luxury and practicality, nothing comes close to a walk-in wardrobe. There are enough spaces to arrange everything properly; it doesn't matter what you have to pick up there; things stay handy and orderly.


                        </p>
                    </div>
                </div>
            </div>
        <div className="container-fluid services p-5">
            <h3 className="services-title-2">Types of Wardrobe Designs</h3>
                    <div className="row services-row">
                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-cube" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Corner wardrobe</h2>
                            <p className="services-para">This is very versatile in terms of maximizing corner space while maintaining an efficient work triangle.
                            ​</p>
                         </div>

                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Free-standing wardrobe</h2>
                            <p className="services-para">A wider configuration generally allowing ample storage and better workspace.
                ​           </p>
                        </div>

                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-building" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Mirrored Wardrobe Design</h2>
                            <p className="services-para">An efficient and sleek layout that makes two parallel countertops to streamline the act of cooking.​</p>
                        </div>
                    </div>
        </div>
    </div>
</div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Wardrobe;
